import React from 'react';
import {
  CodeGenerationIndicatorContainer
} from './CodeGenerationIndicatorElements';
import { Text } from '../Elements/Typography';
import { TailSpin } from 'react-loader-spinner';
import { MdError } from "react-icons/md";
import { Button } from '../Elements/Button';

function CodeGenerationIndicator({ text, isError, onRetryClick }) {
  return (
    <>
      <CodeGenerationIndicatorContainer>
        {
          !isError ? 
          <TailSpin
            color='#fff'
            height={30}
            width={30}
          /> : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '30px', height: '30px', color: 'var(--danger-color)'}}>
            <MdError />
          </div>
        }
        <Text color={isError ? 'var(--danger-color)' : '#fff'} fontSize='16px' fontWeight='500' style={{margin: '0 10px'}}>
          {text}
        </Text>
        {
          isError ? <Button outlined color='#fff' padding='5px 10px' borderRadius={15} style={{margin: '0 10px'}} onClick={onRetryClick}>
            Retry
          </Button> : <></>
        }
      </CodeGenerationIndicatorContainer>
    </>
  );
}

export default CodeGenerationIndicator;
